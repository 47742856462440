<template>
  <div class="h-full w-full max-w-7xl mx-auto px-safe-4 lg:px-8 flex flex-col gap-8">
    <PageHeader title="Import clients" />
    <ImportClients />
  </div>
</template>

<script>
import PageMixin from '@/components/mixins/Page'
import ImportClients from '@/components/importers/ImportClients.vue'
import PageHeader from '@/components/layout/page/PageHeader.vue'

export default {
  components: {
    PageHeader,
    ImportClients
  },
  mixins: [PageMixin],
  name: 'ImportClientsPage'
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
